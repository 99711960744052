import { buildApi } from "@/utils/ajax";

const basicApi = buildApi("/portal/ops");

// 车辆备案列表
export const useEtcVehiclePageApi = basicApi({
  url: "/etc_vehicle/page",
  withCredentials: true
});

// ETC服务/申请记录/列表
export const useEtcQueryApi = basicApi({
  url: "/etc/query",
  withCredentials: true
});
// ETC服务/申请记录/列表
export const useOssBatchDownloadApi = basicApi({
  url: "/oss/batch_download",
  withCredentials: true,
  responseType: "blob",
  meta: { isTransformRequestResult: false }
});

// ETC服务/申请记录/开通、销户
export const useEtcAuditApi = basicApi({
  url: "/etc/audit",
  withCredentials: true
});

// ETC服务/手动同步/待开票订单列表
export const useEtcInvoiceOrderPageApi = basicApi({
  url: "/etc/invoice/order_page",
  withCredentials: true
});

// ETC服务/手动同步/手动同步
export const useEtcInvoiceSyncInvoiceApi = basicApi({
  url: "/etc/invoice/sync_invoice",
  withCredentials: true
});
