<template>
  <div class="page-main-box" id="applicationRecordsBox">
    <!-- 有数据 -->
    <div class="pt-24 pl-16 pr-16">
      <!-- 搜索头 -->
      <search-header
        style="display:none;"
        :schemaData="schema"
        @getDataList="handleFormSearch"
      />

      <!-- 列表 -->
      <div :style="{ height: tableHeight + 'px' }">
        <p-table
          :dataSource="tableDataSource"
          height="auto"
          @sort-change="sortChangeEvent"
        >
          <p-t-column type="seq" width="60" title="序号" fixed="left" />
          <p-t-column field="company_name" min-width="336" title="申请企业">
            <template v-slot="{ row }">
              <span>{{
                row.company_name?.length > 18
                  ? row.company_name?.substr(0, 18) + "..."
                  : row.company_name
              }}</span>
            </template>
          </p-t-column>
          <p-t-column
            field="update_time"
            min-width="209"
            title="申请时间"
            sortable
          >
            <template v-slot="{ row }">
              <span>{{
                formatToDateTime(row.create_time, "yyyy-MM-dd hh:mm:ss")
              }}</span>
            </template>
          </p-t-column>
          <p-t-column field="status_desc" min-width="126" title="状态" />
          <p-t-column width="160" title="操作" fixed="right">
            <template v-slot="{ row }">
              <a-button
                v-if="$store.getters.roles.includes('ApplicationRecordsHandle')"
                type="link"
                @click="handleAudit(row)"
              >
                {{ row.status === "APPROVED" ? "销户" : "开通" }}
              </a-button>
              <a-button
                v-if="
                  $store.getters.roles.includes('ApplicationRecordsDownLoad')
                "
                :disabled="!row.oss"
                type="link"
                @click="handleDownload(row)"
              >
                下载申请材料
              </a-button>
            </template>
          </p-t-column>
        </p-table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, toRaw } from "vue";
import { message } from "ant-design-vue";
import { Table } from "@wlhy-web-components/common";
import { searchHeaderOptions } from "./Constants";
import {
  useEtcQueryApi,
  useOssBatchDownloadApi,
  useEtcAuditApi
} from "@/apis/etc";
import useSearchForm from "@/hooks/UseSearchForm";
import $await from "@/utils/await";
import useTableDataRecords from "@/hooks/useTableDataRecords";
import useTableHeight from "@/hooks/useTableHeight";
import { download } from "@/hooks/useDownloadZip";
import { formatToDateTime } from "@wlhy-web-lib/shared";

export default {
  name: "application-records",
  components: {},
  setup() {
    const etcQueryApi = useEtcQueryApi();
    const etcAuditApi = useEtcAuditApi();
    const ossBatchDownloadApi = useOssBatchDownloadApi();

    // table高度
    const tableHeight = ref();

    // 列表数据
    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });

    const { filter, handleFormSearch } = useSearchForm(searchHeaderOptions);

    // 获取列表数据
    const getList = Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          ...toRaw(filter.value),
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        let [, res] = await $await(etcQueryApi(data));
        res.list.forEach(item => {
          item.company_name = item.company_bo?.name;
        });
        console.log(res);
        return useTableDataRecords(res);
      }
    });

    onMounted(() => {
      tableHeight.value = useTableHeight();
    });

    // 开通/销户操作
    const handleAudit = async row => {
      console.log(row);
      let params = { id: row.id, reject: false };
      if (row.status === "APPROVED") {
        params = { ...params, reject: true };
      }
      const [, res] = await $await(etcAuditApi(params));
      if (res && row.status === "APPROVED") {
        message.success("销户成功！");
        getList();
      } else if (res && row.status !== "APPROVED") {
        message.success("开通成功！");
        getList();
      }
    };

    // 下载申请材料
    const handleDownload = async row => {
      console.log(row);
      let paths = [];
      if (row.oss?.length) {
        paths = row.oss.map(item => {
          return item.filename;
        });
      }
      const [, res] = await $await(ossBatchDownloadApi({ paths }));
      download(res);
    };

    const sortChangeEvent = colums => {
      const { order } = colums;
      filter.value.sort = "update_time " + order;
      getList();
    };

    return {
      tableHeight,
      tableDataSource,
      formatToDateTime,
      handleAudit,
      handleDownload,
      schema: searchHeaderOptions,
      handleFormSearch,
      sortChangeEvent
    };
  }
};
</script>

<style lang="less" scoped>
#applicationRecordsBox {
  position: relative;
  overflow: hidden;
}
</style>
