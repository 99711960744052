// 下载文件方法
export const download = function(content) {
  const blob = new Blob([content], { type: "application/zip" });
  console.log(blob);
  var timestamp = new Date().valueOf();
  const fileName = "附件" + timestamp;
  const elink = document.createElement("a");
  elink.download = fileName;
  elink.style.display = "none";
  elink.href = window.URL.createObjectURL(blob);
  document.body.appendChild(elink);
  elink.click();
  window.URL.revokeObjectURL(elink.href);
  document.body.removeChild(elink);
};
